.poster {
  width: 100%;
  height: 100%;

  &__banner {
    margin-bottom: 2.5em;
  }

  &__category {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
  }

  &__country {
    margin-top: 2.5em;
  }
}
