.advanced-filters {

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 10px;

    &__single {
      margin-right: 15px;
    }
  }

  &__item {
    min-height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
