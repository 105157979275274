.panel-sidebar-menu {
  padding: 30px 0;

  &__items {
    
    &__single {
      padding-bottom: 30px;
      width: 100%;

      &:last-child {
        padding-bottom: 0;
      }

      &__icon {
        padding-left: 15px;
        
        &__image {
          width: 16px;
          height: 16px;
        }
      }

      &__title {
        font-size: 11pt;
        font-weight: 300;
        padding-left: 15px;
        color: #FAFAFA;
        opacity: 0.7;
        position: relative;
        top: 3px;
      }
      
      &__link {
        
        &--active {

          &__title {
            color: @white !important;
            font-weight: 600;
            font-size: 12pt;
            opacity: 0.9;
            text-decoration: underline;
            text-decoration-color: @highlight-primary-color;
            text-decoration-style: solid;
            text-decoration-thickness: 5px;
          }
        }
      }
    }
  }
}