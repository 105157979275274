html,
body {
  font-family: 'Poppins', sans-serif !important;
}

@import '~antd/dist/antd.less';

@import './_parameters';
@import './_globals';

// stacks
@import 'src/app/pages/Panel/PanelNavigationStack';

// pages
@import 'src/app/pages/Auth/Login/Login';
@import 'src/app/pages/Auth/PasswordRecovery/PasswordRecovery';

@import 'src/app/pages/Panel/Dashboard/Details/DashboardDetails';

@import 'src/app/pages/Panel/Admin/Report/AdminReport';
@import 'src/app/pages/Panel/Admin/Details/AdminDetails';

@import 'src/app/pages/Panel/PushNotification/Report/PushNotificationReport';
@import 'src/app/pages/Panel/PushNotification/Details/PushNotificationDetails';

@import 'src/app/pages/Panel/Poster/Report/PosterReport';
@import 'src/app/pages/Panel/Poster/Report/Banner/BannerReport';
@import 'src/app/pages/Panel/Poster/Report/Category/CategoryReport';
@import 'src/app/pages/Panel/Poster/Report/Country/CountryReport';

@import 'src/app/pages/Panel/Poster/Details/Banner/BannerDetails';
@import 'src/app/pages/Panel/Poster/Details/Category/CategoryDetails';
@import 'src/app/pages/Panel/Poster/Details/Country/CountryDetails';

// components
@import 'src/app/components/AdvancedInput/AdvancedInput';
@import 'src/app/components/AdvancedButton/AdvancedButton';
@import 'src/app/components/AdvancedForm/AdvancedForm';
@import 'src/app/components/AdvancedSelect/AdvancedSelect';
@import 'src/app/components/AdvancedRadio/AdvancedRadio';
@import 'src/app/components/AdvancedDateTimePicker/AdvancedDateTimePicker';
@import 'src/app/components/AdvancedCheckbox/AdvancedCheckbox';
@import 'src/app/components/AdvancedUploader/AdvancedUploader';
@import 'src/app/components/DataTable/DataTable';
@import 'src/app/components/PanelSidebar/PanelSidebar';
@import 'src/app/components/PanelSidebarMenu/PanelSidebarMenu';
@import 'src/app/components/PanelContent/PanelContent';
@import 'src/app/components/PanelContentHeader/PanelContentHeader';
@import 'src/app/components/PanelContentTopBar/PanelContentTopBar';
@import 'src/app/components/PanelContentSearchBar/PanelContentSearchBar';
@import 'src/app/components/Loading/Loading';
@import 'src/app/components/DataTableActions/DataTableActions';
@import 'src/app/components/AdvancedFilters/AdvancedFilters';
@import 'src/app/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

@primary-color: #801E2B;