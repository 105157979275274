.panel-dashboard {
  &__title {
    padding: 0 0 15px 0;

    &--inner {
      padding-top: 30px;
    }
  }

  &__bars {
    padding: 30px 0;
  }

  &__welcome {
    color: @primary-color;
    font-size: 25px;
    margin: 0 0 30px 0;
  }
}
