.transparent-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: 0 !important;
  width: auto;
  height: auto;

  &:disabled {
    cursor: default;
  }
}

.form-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  border-top: 1px solid @border-color;
  padding-top: 30px;

  &__single {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

