.panel-content-header {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;

  &__inner {

    &__title {

      &__text {
        font-size: 20pt;
        line-height: 20pt;
        color: black;
        margin-bottom: 0;
      }
    }

    &__description {

      &__text {
        font-size: 11pt;
        color: @gray-text;
        padding-top: 5px;
      }
    }
  }

  &__right {

  }
}
